import React from "react";
import products from "./data/products";
import PantsCard from "./PantsCard";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pantsContainer: {
    marginTop: 10,
  },
}));

const PantsContainer = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.pantsContainer}>
      {products.map((product) => {

        return (
          <Grid item xs={6} md={4}>
            <PantsCard key={product.id} product={product} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PantsContainer;
