import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import PantsContainer from "./PantsContainer";
import GivePage from "./GivePage";

const useStyles = makeStyles((theme) => ({
  casualPants: {
    fontSize: 25,
  },
  pantsContainer: {
    margin: 10,
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  fakeNavTrail: {
    color: "grey",
  },
  containerContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const HomePage = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.containerContainer}>
        <div className={classes.pantsContainer}>
          <Typography className={classes.fakeNavTrail}>Home / Men /</Typography>
          <Typography className={classes.casualPants} color="primary">
            Casual Pants
          </Typography>
          <PantsContainer />
        </div>
      </div>
      <div>
        <GivePage />
      </div>
    </>
  );
};

export default HomePage;
