import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
import formatPrice from "./util/formatPrice";
import { Link } from "react-router-dom";

// Pants
import image1 from './data/1.jpeg';
import image2 from './data/2.jpeg';
import image3 from './data/3.jpeg';
import image4 from './data/4.jpeg';
import image5 from './data/5.jpeg';
import image6 from './data/6.jpeg';

const getImagePath = (id) => {
  switch (id) {
    case 1:
      return image1;
    case 2:
      return image2;
    case 3:
      return image3;
    case 4:
      return image4;
    case 5:
      return image5;
    case 6:
      return image6;
    default:
      return null; // Return a default image or null if the ID doesn't match any images
  }
};

const useStyles = makeStyles((theme) => ({
  pantsImage: {
    width: "100%",
    height: 300,
    objectFit: "cover",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      height: 400,
    },
    "&:hover": {
      opacity: 0.8,
    },
  },
  pantCardContainer: {
    marginBottom: 10,
  },
  productName: {
    fontWeight: "bold",
    fontSize: 15,
  },
  productPrice: {
    textDecoration: "line-through",
  },
  priceContainer: {
    display: "flex",
  },
}));

const PantsCard = ({ product }) => {
  const classes = useStyles();
  const imagePath = getImagePath(product.id);

  return (
    <div className={classes.pantCardContainer}>
      <Link to="/give">
        <img
          src={imagePath}
          alt="pants"
          className={classes.pantsImage}
        />
      </Link>
      <Typography className={classes.productName}>{product.name}</Typography>
      <div className={classes.priceContainer}>
        <Typography className={classes.productPrice}>
          {formatPrice(product.price)}
        </Typography>
        <Typography style={{ marginLeft: 5 }}>Sold Out</Typography>
      </div>
    </div>
  );
};

export default PantsCard;
