const products = [
  {
    id: 1,
    name: "Extra Long Officer's Pants",
    price: 199,
  },
  {
    id: 2,
    name: 'Bootcut "Ramlinero" Jeans',
    price: 89,
  },
  {
    id: 3,
    name: "Stretch Washed Chinos",
    price: 120,
  },
  {
    id: 4,
    name: "Frickin Skate Chino Pants",
    price: 145,
  },
  {
    id: 5,
    name: "Slim-fit Stretch Pant",
    price: 76,
  },
  {
    id: 6,
    name: "Surf N' Turf Pants",
    price: 188,
  },
];

export default products;
