const theme = {
  palette: {
    primary: {
      main: "#1f3d5e",
    },
    secondary: {
      main: "#24476e",
    },
  },
  typography: {
    fontFamily: "Cormorant Garamond, serif",
  },
  global: {
    progressContainerPosts: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 60,
    },
  },
};
export default theme;
