import React from "react";
import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    color: "#d0e0f2",
    textAlign: "center",
    fontSize: 15,
  },
  AppBar: {
    width: "100%",
    boxShadow: "none",
  },
}));

const FlashSale = () => {
  const classes = useStyles();
  return (
    <AppBar
      position="static"
      color="secondary"
      className={classes.AppBar}
      style={{ margin: 0 }}
    >
      <Toolbar style={{ padding: 0 }}>
        <Typography variant="h6" className={classes.title}>
          FLASH SALE! Up to 60% off Pants & Belts for Summer!
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default FlashSale;
