import { makeStyles, Typography, Grid, Button } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  giveContainer: {
    backgroundColor: "#24476e",
    marginTop: 20,
    width: "100%",
    paddingBottom: 50,
  },
  title: {
    color: "#d0e0f2",
    textAlign: "center",
    paddingTop: 20,
  },
  giveImage: {
    width: "50%",
    borderRadius: "20px",
    marginTop: 10,
  },
  button: {
    textDecoration: "none",
    marginTop: 40,
    backgroundColor: "#fff",
    fontSize: 16,
    marginLeft: 10,
    marginRight: 10,
  },
}));

const GiveContainer = () => {
  const classes = useStyles();

  const onButtonClick = () => {
    let path = `https://venmo.com/chicagohomeless`;
    window.open(path);
  };

  return (
    <div className={classes.giveContainer}>
      <Typography className={classes.title} variant="h3">
        You Gotta Give.
      </Typography>
      <Typography className={classes.title} variant="body1">
        If nobody gives, it goes dark
      </Typography>
      <Grid container spacing={3} align="center" justify="center">
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            onClick={onButtonClick}
            className={classes.button}
          >
            Venmo: Click to Donate to the Chicago Coalition for the Homeless
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default GiveContainer;
