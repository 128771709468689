import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(0),
    color: "#1f3d5e",
  },
  title: {
    flexGrow: 1,
    color: "#1f3d5e",
    textAlign: "center",
    fontSize: 25,
    fontStyle: "italic",
    textDecoration: "none",
  },
  AppBar: {
    backgroundColor: "#fff",
    width: "100%",
  },
}));

const Nav = () => {
  const classes = useStyles();
  return (
    <AppBar position="sticky" className={classes.AppBar} style={{ margin: 0 }}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>

        <Typography
          variant="h6"
          className={classes.title}
          component={Link}
          to="/"
        >
          Calico Cut Pants
        </Typography>
        <IconButton
          edge="start"
          className={classes.menuButton}
          aria-label="menu"
        >
          <LocationOnIcon />
        </IconButton>
        <IconButton
          edge="start"
          className={classes.menuButton}
          aria-label="menu"
        >
          <ShoppingBasketOutlinedIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
