import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "./HomePage";
import GivePage from "./GivePage";
import Nav from "./Nav";
import FlashSale from "./FlashSale";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import themeFile from "./util/theme";
import "./App.css";
import ReactGA from "react-ga4";
//ReactGA.initialize("G-RWNY6FLM41");
ReactGA.initialize("G-TR7JM1ZTKH");
const theme = createTheme(themeFile);

const App = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <BrowserRouter >
          <Nav />
          <FlashSale />
          <Switch>
            <Route exact path="/" >
              <HomePage />
            </Route>
            <Route path="/give" >
              <GivePage />
            </Route>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
